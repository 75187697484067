footer {
    width: 100%;
    height: 350px;
    background-color: white;
    overflow: hidden; 
}

.footer_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5%
}

.footer_content img {
    max-width: 100%;
}

.footer_content .logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    width: 100%;
}


.footer_content .timeline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    width: 100%;
    position: relative; 
}

.footer_content .timeline::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 85%;
    height: 4px; 
    background: linear-gradient(90deg, #4F3138 0%, #CE1534 100%);
    top: 26%;
}

.footer_content .socials {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 60px;
    gap: 71.5px;
}

.footer_content .footer_title {
    width: 188.5px;
    height: 66.498px;
    flex-shrink: 0;
}

.footer_content .footer_logo {
    width: 40.069px;
    height: 48px;
}

@media (max-width: 1023px) {
    footer {
        width: 100%;
        height: 700px;
        overflow: hidden; 
    }
    
    .footer_content .title, .footer_content .logo {
        width: auto;
    }
    .footer_content .timeline {
        flex-direction: column;
        gap: 30px;
    }
    .footer_content .timeline::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 4px;
        height: 85%; 
        background: linear-gradient(180deg, #4F3138 0%, #CE1534 100%);
        top: 12%;
    }
}

@media (max-width: 767px) {
    .footer_content .socials {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 50px;
        padding-bottom: 50px;
        gap: 30px;
    }
    
    .footer_content img {
        min-width: 26.113px;
        min-height: 26.068px;
        flex-shrink: 0;
    }
}