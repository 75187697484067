/* Existing styles */
.buy-now-page {
    color: white;
    background-color: #000000; /* Dark background for contrast */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Adjusted to start at the top */
    align-items: center;
    padding: 40px 20px;
    min-height: 100vh; /* Ensure it covers full viewport height */
    text-align: center;
    font-family: 'Roboto', sans-serif; /* Use a clean, modern font */
}

.buy-now-page h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #fff;
}

.buy-now-page p {
    font-size: 1.2rem;
    line-height: 1.5;
    max-width: 600px;
    margin-bottom: 15px;
    color: #ddddddc1;
}

/* New styles for the countdown timer */
.countdown-timer {
    margin-top: 30px;
    font-size: 1.5rem;
    color: #fff;
}

.countdown-timer h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ff4747; /* Use a standout color for the heading */
}

.closed_sales h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ff4747; /* Use a standout color for the heading */
}


.time-left {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.time-segment {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
}

.time-segment span {
    font-size: 3rem;
    font-weight: bold;
    color: #ffffff; /* Use a bright color for numbers */
}

.time-segment label {
    font-size: 1rem;
    color: #ccc;
    margin-top: 5px;
}

/* Style the Stripe pricing table container */
.stripe-pricing-table {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

/* Responsive design */
@media (max-width: 768px) {
    .buy-now-page h1 {
        font-size: 2.5rem;
    }
    
    .time-left {
        flex-direction: column;
    }
    
    .time-segment {
        margin: 10px 0;
    }
}

@media (max-width: 480px) {
    .buy-now-page {
        padding: 20px 10px;
    }
    
    .buy-now-page h1 {
        font-size: 2rem;
    }
    
    .countdown-timer h2 {
        font-size: 1.5rem;
    }
    
    .time-left {
        font-size: 2rem;
    }

    .time-segment span {
        font-size: 2.5rem;
    }
}
