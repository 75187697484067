.speakerbig {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    width: 75%;
}

.speakersmall{
    display: none
}

.speaker-profile-pic {
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    margin-left: 5vw;
    margin-right: 5vw;
    position: relative;
    z-index: 2;
    opacity: 0;
    animation: fadeIn 1s forwards;
}

.name {
    font-size: 2vw;
    font-weight: 600;
    color: #ff2b06;
    display: flex;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    opacity: 0;
    animation: fadeIn 1s forwards;
}

.speaker-image-container {
    z-index: 1000;
    display: flex;
    flex-direction: row;
}

.position {
    font-size: 16px;
    color: white;
    margin-top: 10px;
    position: relative;
    z-index: 1;
    opacity: 0;
    animation: fadeIn 1s forwards;
}

.speakerabout {
    color: #D3D3D3;
    text-align: justify;
    font-size: 0.9vw;
}

@media (max-width: 1100px) {
    .speakerbig {
        display: none;
    }

    .speakersmall {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        width: 75%;
    }

    .speaker-image-container {
        float: left;
        justify-content: center;
    }

    .speaker-profile-pic {
        width: 40vw;
        height: 40vw;
    }

    .name {
        font-size: 4vw;
    }

    .position {
        margin-top: 3px;
        font-size: 13px;
    }

    .speakerabout{
        font-size: 15px;
    }
}

@media (max-width: 500px) {
    .speaker-profile-pic {
        width: 50vw;
        height: 50vw;
        margin-right: 15px;
    }

    .speakerabout {
        clear: both;
    }
}
