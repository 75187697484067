.talk-content {
    width: 100%;
    max-width: 570px;
    margin: 30px 0;
    border: none;
    border-radius: 20px;
    background: #2F2F2F;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.iframe-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    border-radius: 20px;
    overflow: hidden;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 20px;
}