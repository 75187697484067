:root {
    --red-colour: #EB0028;

}

*,
*::after,
*::before {
    box-sizing:border-box;
}

html, body {
    font-family: "Inter", sans-serif;
    /* width: 100%;
    height: 100%; */
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
}
