a {
    text-decoration: none;
}

.card-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 171.828px;
    height: 89.387px;
    flex-shrink: 0;
    background-color: #EAEAEA;
    border-radius: 15px;
    text-align: center;
    line-height: 20px;
    font-family: Arial, sans-serif;
    color: black;
    padding: 0 30px;
}

.year-box {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 135.807px;
    height: 32.152px;
    flex-shrink: 0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 97.214px;
    /* 388.856% */
    letter-spacing: -0.5px;
}

.text-box {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 550;
    line-height: 18.214px;
    /* 121.427% */
    letter-spacing: -0.3px;
    width: 150px;
}

@media (max-width: 1023px) {
    a {
        width: 100%;
    }

    .card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 3px solid #FFF;
        line-height: 50px;
        font-family: Arial, sans-serif;
        color: black;
        width: 100%;
        height: 62px;
        padding: 0 0;
    }

    .mobile-year-box {
        text-align: center;
        color: #FFF;
        font-family: Inter;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 97.214px;
        letter-spacing: -0.5px;
        width: 35%;
    }
    
    .mobile-text-box {
        text-align: left;
        color: #FFF;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 450;
        line-height: 97.214px;
        width: 65%;
    }
}

@media (max-width: 767px) {
    .mobile-year-box {
        text-align: center;
        color: #FFF;
        font-family: Inter;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 97.214px;
        /* 388.856% */
        letter-spacing: -0.5px;
        width: 30%;
    }
    
    .mobile-text-box {
        text-align: left;
        color: #FFF;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 97.214px;
        /* 648.094% */
        letter-spacing: -0.3px;
        width: 70%;
    }
}

@media (max-width: 344px) {
    .mobile-year-box {
        text-align: left;
        color: #FFF;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 97.214px;
        /* 388.856% */
        letter-spacing: -0.5px;
        width: 20%;
    }
    
    .mobile-text-box {
        text-align: left;
        color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 97.214px;
        /* 648.094% */
        letter-spacing: -0.3px;
        width: 70%;
    }
}