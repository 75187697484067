/* NavBar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 85px;
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 1000;
}

.navbar-logo {
  height: 52px;
  padding-left: 32px;
}

.navbar-menu-icon {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
  width: 32px;
  height: 32px;
}


.navbar-links {
  display: flex;
}

.navbar-links .navbar-tabs {
  margin-right: 50px;
}

.navbar-links .navbar-tabs, .social-icons {
  display: flex;
  gap: 5rem;
  align-items: center;
  font-size: 1.1rem;
}

.navbar-links .navbar-tabs a, .navbar-links .social-icons a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #ff2b06; /* TEDx red color */
  text-decoration: none;
}

.navbar-links .navbar-tabs .active-link{
  text-decoration: underline;
  text-decoration-color: #ff2b06;
  text-decoration-thickness: 3px;
}

.navbar-links .navbar-tabs .ticket-form {
  color: #ff2b06; 
}

.navbar-links .social-icons {
  display: flex;
  gap: 2.5rem;
}

.navbar-links .custom-icon {
  width: 32px;
  height: 32px;
}

@media (max-width: 1100px) {
  .navbar-menu-icon {
    display: block;
  }

  .navbar-links .navbar-tabs {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: black;
    padding: 1rem;
  }

  .navbar-links .social-icons {
    display: flex;
    gap: 1rem;
  }
}