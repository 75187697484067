.wrapper {
    width: 100%;
    background: linear-gradient(0deg, #000 0%, #2A2A2A 60%, #000 80%);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.background {
    width: 100vw;
    height: 85vh;
    background: url('../../images/GleamingStars.svg') center center;
    background-size: cover;
    position: absolute;
    top: 85vh;
    left: 0;
    z-index: 998;
}

.mobilebackground {
    width: 100vw;
    height: 85vh;
    background: url('../../images/GleamingStarsMobile.svg') center center;
    background-size: cover;
    position: absolute;
    top: 85vh;
    left: 0;
    z-index: 998;
}

.background2 {
    width: 100vw;
    height: 85vh;
    background: url('../../images/GleamingStars.svg') center center;
    background-size: cover;
    position: absolute;
    top: 85vh;
    left: 0;
    z-index: 998;
}

.background3 {
    width: 100vw;
    height: 85vh;
    background: url('../../images/GleamingStars.svg') center center;
    position: relative;
    background-size: cover;
    z-index: 1002;
}

.desktopclouds {
    width: 100vw;
    height: 85vh;
    background: url('../../images/DesktopClouds.svg') center center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 998;
} 

.mobileclouds {
    width: 100%;
    height: 85vh;
    background: url('../../images/MobileClouds.svg') center center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 998;
}

.mobileclouds, .mobilebackground {
    display: none;
}

@keyframes homeMoveRightToLeft {
    from { right: -10%; }
    to { right: 105%; }
}

@keyframes homeMoveRightToLeft2 {
    from { right: -13%; }
    to { right: 102%; }
}

@keyframes homeMoveLeftToRight {
    from { left: -2%; }
    to { left: 108%; }
}

@keyframes homeMoveLeftToRight2 {
    from { left: -10%; }
    to { left: 100%; }
}

.homepage-clouds {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.homepage-clouds .cloud {
    position: absolute;
    opacity: 0.6; 
}

.homepage-clouds .cloud1 {
    top: 12%; 
    animation: homeMoveLeftToRight 120s linear infinite; 
}

.homepage-clouds .cloud2 {
    top: 9%; 
    animation: homeMoveLeftToRight2 120s linear infinite; 
}

.homepage-clouds .cloud3 {
    top: 22%; 
    animation: homeMoveRightToLeft 120s linear infinite; 
}

.homepage-clouds .cloud4 {
    top: 24%; 
    animation: homeMoveRightToLeft2 120s linear infinite; 
}

@media (max-width: 1100px) {
    .mobilebackground, .mobileclouds {
        display: block;
    }

    .background, .desktopclouds {
        display: none;
    }
}

.graywaves {
    width: 110%;
    z-index: 1000;
    margin-top: -25vw;
    position: relative;
}

.graywave{
    width: 100%;
}

.graywavecontent {
    margin-top: -100vw;
}

.redwavescontent {
    margin-top: -196vw;
    justify-content: center;
}

.impact {
    z-index: 1001;
    margin-left: 7vw;
}

.impactTitle {
    color: #FFF;
    font-family: Inter;
    font-size: 5vw;
    font-weight: 700;
    width:20%;
}

.impactSubtitle {
    color: red;
    margin-top: 1.2vw;
    font-family: Inter;
    font-size: 2.8vw;
    font-weight: 400;
}

.gallery {
    position: relative;
    z-index: 1003;
    margin-top: -8vw;
}

.gallerytitle {
    color: #FFF;
    font-family: Inter;
    font-size: 5vw;
    font-weight: 700;
    width:20%;
    margin-left: 70vw;
    align-items: flex-end;
    text-align: right;
}

.gallerysubtitle {
    color: red;
    margin-top: 1.2vw;
    font-family: Inter;
    font-size: 2.8vw;
    font-weight: 400;
    margin-left: 57vw;
}

.carousel {
    position: relative;
    z-index: 1003;
    border-radius: 30px;
    height: 50vw;
    margin-top: 50px;
}

.statistics-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
    background-color: #000;
    color: #fff;
    width: 100%;
    margin-top: 7vw;
    margin-left: 1vw;
  }
  
  .col-stat-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 4vw;
    padding: 4vw;
    text-align: center;
  }

  .row-stat-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: black;
    border-radius: 4vw;
    padding: 4vw;
    text-align: center;
  }
    
  .stat-number {
    font-size: 15vw;
    font-weight: bold;
  }

  .stat-number7 {
    font-size: 6vw;
    font-weight: bold;
  }

  .stat-number25 {
    font-size: 8vw;
    font-weight: bold;
  }
  
  .stat-label {
    font-size: 2.25vw;
  }

.delegatespots {
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.delegatespots.visible {
    opacity: 1;
    transform: translateX(0);
}

.sponsorshipamount {
    opacity: 0;
    transform: translateX(-200px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.sponsorshipamount.visible {
    opacity: 1;
    transform: translateX(0);
}

.speakercount {
    opacity: 0;
    transform: translateX(100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.speakercount.visible {
    opacity: 1;
    transform: translateX(0);
}

.organizercount {
    opacity: 0;
    transform: translateX(200px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.organizercount.visible {
    opacity: 1;
    transform: translateX(0);
}

.redwaves {
    width: 110%;
    z-index: 1002;
    margin-top: -3vw;
    position: relative;
    margin-left: -5px;
    overflow: hidden;
}

.redwave{
    width: 100%;
    z-index: 1002;
    position: relative;
}

.speaker1 {
    z-index: 1000;
    margin-top: -30vw;
    margin-left: 40%;
}

.speaker1image {
    width: 50vw;
}

.friendspic {
    position: relative;
    z-index: 1001;
    margin-left: 5vw;
}

.friendsimage {
    position: relative;
    width: 50vw;
}

.mainText {
    margin-top: 100px;
    color: white;
    text-align: center;
    animation: fadeIn 2s ease-out forwards;
    animation-delay: 1.25s;
    opacity: 0;
    position: relative; /* Added */
    z-index: 1010;      /* Added, higher than graywaves z-index of 1000 */
}


@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.title {
    width: 50%;
}

.title h1 {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 116px;
    font-weight: 700;
    line-height: 120px;
    letter-spacing: -1.92px;
    margin-bottom: 10px;
    margin-top: 0px;
}

.subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 0px;
}

.info {
    text-align: center;
    color: #FFF;
    font-family: Inter;
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.5px;
}

.tedxLogo {
    padding-left: 25px;
    width: 111px;
    height: auto;
    flex-shrink: 0;
}

.whatisTedX {
    width: 90%;
    color: #FFF;
    background-color: black;
    padding: 40px 30px;
    border-radius: 100px;
    border: 5px solid grey;
    z-index: 999;
    margin: 200px 0;
    opacity: 0;
    transform: translateY(200px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.whatisTedX.visible {
    opacity: 1;
    transform: translateY(0);
}

.whatisTedXHeader {
    color: red;
    font-family: Inter;
    font-size: 5vw;
    font-weight: 700;
    margin-left: 3%;
}

.whatisTedXBody, .whatisTedXBody2 {
    position: relative;
    font-family: Inter;
    font-weight: 400;
    margin-top: 20px;
    font-size: 1.75vw;
    z-index: 1001;
    margin-left: 3%;
}

.ourmission {
    width: 80%;
    color: #FFF;
    background-color: black;
    padding: 40px 30px;
    border-radius: 100px;
    border: 5px solid grey;
    z-index: 1002;
    position: relative;
    margin-left: 7vw;
    margin-bottom: 100px;
    opacity: 0;
    transform: translateY(200px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.ourmission.visible {
    opacity: 1;
    transform: translateY(0);
}

.ourmissionHeader {
    color: red;
    font-family: Inter;
    font-size: 5vw;
    font-weight: 700;
    margin-left: 4%;
}

.ourmissionBody {
    position: relative;
    font-family: Inter;
    font-weight: 400;
    margin-top: 20px;
    font-size: 1.75vw;
    z-index: 1001;
    margin-left: 4%;
}

.whatisTedXBody2{
    width: 65%;
}


@media (max-width: 768px) {
    .title {
        width: 311px;
    }

    .title h1 {
        font-size: 57px;
        line-height: 59px;
        letter-spacing: -1.14px;
    }

    .info {
        font-size: 16px;
        line-height: 30px;
    }

    .whatisTedXBody, .whatisTedXBody2 {
        font-size: 4vw;
    }

    .whatisTedXBody2 {
        width: 100%;
    }

    .ourmissionBody {
        font-size: 4vw;
    }

    .speaker1{
        margin-top: -75vw;
    }

    .speaker1image {
        width: 100vw;
    }

    .graywaves {
        margin-top: -30vw;
    }

    .mainText {
        margin-top: 150px;
    }

    .impactTitle{
        font-size: 6vw;
    }

    .impactSubtitle {
        font-size: 3.5vw;
    }

    .gallerytitle{
        font-size: 6vw;
    }

    .gallerysubtitle {
        font-size: 3.5vw;
        margin-left: 50vw;
    }

    .statistics-container{
        margin-left: -2vw;
        margin-top: 1vw;
    }

    .background3 {
        background: url('../../images/GleamingStarsMobile.svg') center center;
    }
}

.buy-now-button {
    display: inline-block;
    padding: 15px 40px;
    background-color: #ff4747; /* Bright red */
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 50px; /* Makes the button round */
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease;
    cursor: pointer; /* Ensures the pointer cursor on hover */
    z-index: 10; /* Ensures button is on top of other elements */
}

.buy-now-button:hover {
    background-color: #cc3838; /* Darker red on hover */
}

.buy-now-button:active {
    background-color: #a62626; /* Even darker red when clicked */
}

.watch{
    display: inline-block;
    padding: 15px 40px;
    background-color: #ff4747; /* Bright red */
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 50px; /* Makes the button round */
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease;
    cursor: pointer; /* Ensures the pointer cursor on hover */
    z-index: 10; /* Ensures button is on top of other elements */
}
.lottie-animation {
    position: absolute;
    z-index: 100000;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
  }
  